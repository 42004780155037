import { Outlet } from "react-router-dom";
import { AdminNavbar } from "../../components/navbar/adminNavbar/AdminNavbar";
import React, { ReactElement, useState } from "react";
import { addLocale } from "primereact/api";

addLocale('pt-br', {
  "accept": "Sim",
  "addRule": "Adicionar Regra",
  "am": "AM",
  "apply": "Aplicar",
  "cancel": "Cancelar",
  "choose": "Escolher",
  "chooseDate": "Escolher Data",
  "chooseMonth": "Escolher Mês",
  "chooseYear": "Escolher Ano",
  "clear": "Limpar",
  "contains": "Contém",
  "custom": "Personalizado",
  "dateAfter": "Data depois de",
  "dateBefore": "Data antes de",
  "dateFormat": "dd/MM/yy",
  "dateIs": "Data é",
  "dateIsNot": "Data não é",
  "dayNames": [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado"
  ],
  "dayNamesMin": [
    "D",
    "S",
    "T",
    "Q",
    "Q",
    "S",
    "S"
  ],
  "dayNamesShort": [
    "Dom",
    "Seg",
    "Ter",
    "Qua",
    "Qui",
    "Sex",
    "Sáb"
  ],
  "emptyFilterMessage": "Nenhum resultado encontrado",
  "emptyMessage": "Nenhuma opção disponível",
  "endsWith": "Termina com",
  "equals": "Igual",
  "fileSizeTypes": [
    "B",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB"
  ],
  "filter": "Filtro",
  "firstDayOfWeek": 0,
  "gt": "Maior que",
  "gte": "Maior ou igual a",
  "lt": "Menor que",
  "lte": "Menor ou igual a",
  "matchAll": "Corresponder Todos",
  "matchAny": "Corresponder Qualquer",
  "medium": "Médio",
  "monthNames": [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ],
  "monthNamesShort": [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ],
  "nextDecade": "Próxima Década",
  "nextHour": "Próxima Hora",
  "nextMinute": "Próximo Minuto",
  "nextMonth": "Próximo Mês",
  "nextSecond": "Próximo Segundo",
  "nextYear": "Próximo Ano",
  "noFilter": "Sem Filtro",
  "notContains": "Não contém",
  "notEquals": "Diferente",
  "now": "Agora",
  "passwordPrompt": "Digite uma senha",
  "pending": "Pendente",
  "pm": "PM",
  "prevDecade": "Década Anterior",
  "prevHour": "Hora Anterior",
  "prevMinute": "Minuto Anterior",
  "prevMonth": "Mês Anterior",
  "prevSecond": "Segundo Anterior",
  "prevYear": "Ano Anterior",
  "reject": "Não",
  "removeRule": "Remover Regra",
  "startsWith": "Começa com",
  "strong": "Forte",
  "today": "Hoje",
  "upload": "Enviar",
  "weak": "Fraco",
  "weekHeader": "Sem",
  "aria": {
    "cancelEdit": "Cancelar Edição",
    "close": "Fechar",
    "collapseRow": "Recolher Linha",
    "editRow": "Editar Linha",
    "expandRow": "Expandir Linha",
    "falseLabel": "Falso",
    "filterConstraint": "Restrição de Filtro",
    "filterOperator": "Operador de Filtro",
    "firstPageLabel": "Primeira Página",
    "gridView": "Visualização de Grade",
    "hideFilterMenu": "Esconder Menu de Filtro",
    "jumpToPageDropdownLabel": "Ir para a Página",
    "jumpToPageInputLabel": "Ir para a Página",
    "lastPageLabel": "Última Página",
    "listView": "Visualização de Lista",
    "moveAllToSource": "Mover Todos para a Origem",
    "moveAllToTarget": "Mover Todos para o Destino",
    "moveBottom": "Mover para o Final",
    "moveDown": "Mover para Baixo",
    "moveTop": "Mover para o Topo",
    "moveToSource": "Mover para a Origem",
    "moveToTarget": "Mover para o Destino",
    "moveUp": "Mover para Cima",
    "navigation": "Navegação",
    "next": "Próximo",
    "nextPageLabel": "Próxima Página",
    "nullLabel": "Não Selecionado",
    "otpLabel": "Insira o caractere da senha de uso único {0}",
    "pageLabel": "Página {page}",
    "previous": "Anterior",
    "previousPageLabel": "Página Anterior",
    "rotateLeft": "Rotacionar para a Esquerda",
    "rotateRight": "Rotacionar para a Direita",
    "rowsPerPageLabel": "Linhas por página",
    "saveEdit": "Salvar Edição",
    "scrollTop": "Rolar para o Topo",
    "selectAll": "Todos os itens selecionados",
    "selectRow": "Linha Selecionada",
    "showFilterMenu": "Mostrar Menu de Filtro",
    "slide": "Deslizar",
    "slideNumber": "Slide {slideNumber}",
    "star": "1 estrela",
    "stars": "{star} estrelas",
    "trueLabel": "Verdadeiro",
    "unselectAll": "Todos os itens desmarcados",
    "unselectRow": "Linha Desmarcada",
    "zoomImage": "Ampliar Imagem",
    "zoomIn": "Ampliar",
    "zoomOut": "Reduzir"
  }
});

const Admin = (): ReactElement => {
  return (
    <>
      <AdminNavbar />
      <Outlet />
    </>
  );
};

export default Admin;
